<template>
  <div>
    <b-col
      md="12"
      class="px-0"
    >
      <b-breadcrumb :items="breadcrumbItems" />
      <div v-if="activeHotSpotLink"
           class="row"
      >
        <div class="col-4">
          <b-form-group label="Hot spot Links">
            <b-form-select v-model="activeHotSpotLink"
                           :options="hotSpotLinks"
                           text-field="text"
                           value-field="value"
            />
          </b-form-group>
        </div>
      </div>
      <component
        :is="components[mode]"
        :problem-data="problemData"
        :domains="domains"
        :subject-list="subjectList"
        :tags="tags"
        @showQuestionPreview="showPreviewModal"
        @fetch="onFetch"
        @ready="onReady"
        @copyProblem="copyProblem"
      />
    </b-col>
    <question-preview
      :key="showPreview"
      :show="showPreview"
      :data="previewData"
      :show-hot-spot="problemData? problemData.hotspot_point!==null : false"
      @close="showPreview=false"
    />
  </div>

</template>

<script>
import {
  BBreadcrumb,
  BCol,
  BFormSelect,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { getIdFromURL } from 'vue-youtube-embed'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import MatchingForm from './matching-form/Index.vue'
import FillBlanksForm from './fill-blanks-form/Index.vue'
import MultipleChoiceForm from './multiple-choice-form/Index.vue'
import SpeechForm from './speech-form/Index.vue'
import TextSelectionForm from './text-selection-form/Index.vue'
import QuestionPreview from '../../../common/components/QuestionPreview.vue'
import CategoryForm from './category-form/Index.vue'
import OpenEndWriting from './open-end-writing-form/Index.vue'
import DragPositionForm from './drag-position-form/index.vue'

export default {
  components: {
    BCol,
    BFormSelect,
    BFormGroup,
    MatchingForm,
    FillBlanksForm,
    BBreadcrumb,
    MultipleChoiceForm,
    SpeechForm,
    QuestionPreview,
    TextSelectionForm,
    CategoryForm,
    OpenEndWriting,
    DragPositionForm,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    mode: '',
    problem: null,
    breadcrumbItems: null,
    showPreview: false,
    previewData: {},
    domains: [],
    subjectList: null,
    components: {
      blank: 'fill-blanks-form',
      fib_dropdown: 'fill-blanks-form',
      matching: 'matching-form',
      speech: 'speech-form',
      multiple: 'multiple-choice-form',
      text_selection: 'text-selection-form',
      matching_listening: 'matching-form',
      multiple_listening: 'multiple-choice-form',
      category: 'category-form',
      drag_position: 'drag-position-form',
      'open-end-writing': 'open-end-writing',
      'open-end-writing-speech': 'open-end-writing',
    },
    auth: getUserData(),
    hasRegisteredEvents: false,
    updatingLayout: false,
    updatingToggle: false,
    activeHotSpotLink: null,
  }),
  computed: {
    problemData() {
      if (this.hotSpotLinks.length > 0 && this.activeHotSpotLink) {
        const activeSpot = this.hotSpotLinks.find(item => item.value === this.activeHotSpotLink)
        const activeProblem = { ...this.problem }
        activeProblem.hotspot_point = activeSpot.hotspot_point
        activeProblem.problem_statements = activeProblem.problem_statements.filter(item => item.id === activeSpot.problem_statement_id)
        activeProblem.questions = activeProblem.questions.filter(item => activeSpot.question_ids.includes(item.id))
        return activeProblem
      }
      return this.problem
    },
    hotSpotLinks() {
      if (this.problem?.hotspot_links) {
        return this.problem.hotspot_links.map((item, index) => ({
          text: `Point ${index + 1}`,
          value: item.id,
          ...item,
        }))
      }
      return []
    },
    tags() {
      return this.$store.state.problem.create.tags
    },
  },
  created() {
    this.onFetch()
    useJwt.getDomainByLesson(this.$route.query.lesson_id)
      .then(res => {
        this.domains = [{ value: null, text: 'Please select a domain' }, ...res.data.data.domain.map(domain => ({
          value: domain.id, text: domain.name,
        }))]
        this.breadcrumbItems = [
          {
            text: res.data.data.name,
            href: `/${this.auth.usertype}/courses`,
          },
          {
            text: i18n.tc('actions.problem'),
            href: `/${this.auth.usertype}/problem?lesson_id=${this.$route.query.lesson_id}`,
          },
          {
            text: i18n.tc('edit'),
            active: true,
          },
        ]
      })
    useApollo.getCefrSubject().then(res => {
      const cerfList = res.data.cefrGrade.data[0]?.subjects
      this.subjectList = [
        { value: null, text: 'Please select a subject' },
        ...cerfList.map(st => ({
          value: st.id,
          text: st.name,
          ...st,
        })),
      ]
    })
    this.onReady()
    if (!this.hasRegisteredEvents) {
      this.hasRegisteredEvents = true
      this.$root.$off('toggleAnimatedLayout')
      this.$root.$on('toggleAnimatedLayout', value => {
        this.toggleAnimatedLayouts(value)
      })
      this.$root.$off('updateAnimatedLayout')
      this.$root.$on('updateAnimatedLayout', (id, payload) => {
        this.updateAnimatedLayout(id, payload)
      })
      this.$root.$off('createAnimatedLayout')
      this.$root.$on('createAnimatedLayout', payload => {
        this.createAnimatedLayout(payload)
      })
    }
    this.$store.dispatch('app/getLanguageLevelByCourse', {
      lesson_id: this.$route.query.lesson_id,
    })
  },
  methods: {
    onFetch() {
      useJwt.getProblem(this.$route.query.problem_id).then(response => {
        const res = response.data.data
        this.mode = res.problem_type.name
        const linearLangLevel = Number(res.questions[0]?.lang_level)
        if (res.isLinearLesson && !res.problem_statements[linearLangLevel - 1]) {
          const statement = res.problem_statements[0]
          statement.lang_level = linearLangLevel
          // eslint-disable-next-line prefer-destructuring
          res.problem_statements[linearLangLevel - 1] = statement
        }
        this.problem = res
        this.$store.commit('problem/UPDATE_ACTIVE_PROBLEM', res)
        if (!this.activeHotSpotLink) {
          this.activeHotSpotLink = res.hotspot_links?.[0]?.id
        }
        this.$root.$emit('reSyncAnimationTemplateId', res.animated_template_id)
      })
    },
    showPreviewModal(data) {
      if (data.statement?.video) {
        const { video } = data.statement
        const formattedVideo = {
          url: video.video.url, time: video.time, endTimeInMs: this.timeToMilliseconds(video.time), startTimeInMs: this.timeToMilliseconds('0:0'), youtubeId: getIdFromURL(video.video.url),
        }
        this.previewData = { ...data, statement: { ...data.statement, video: formattedVideo } }
      } else if (this.problemData.hotspot_point) {
        let formattedHotspot = {}
        const hotspot = this.problemData.hotspot_point
        const { coordinates } = hotspot

        formattedHotspot = {
          file: hotspot.hotspot.image,
          problem_type: data.problemType,
          statement: data.statement.text,
          feedback: data.feedback.text,
          statement_audio: data.statement.audio,
          feedback_audio: data.feedback.audio,
          hotspotPoints: {
            coordinates,
            statement_visited: false,
            feedback_visited: false,
            answered: false,
          },
          questions: data.questions,
        }
        this.previewData = { ...data, hotspot: formattedHotspot }
      } else this.previewData = data
      this.showPreview = true
    },
    onReady() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    },
    timeToMilliseconds(duration) {
      const [minutes, seconds] = duration.split(':')
      const millisec = Number(minutes) * 60 + Number(seconds)
      return Number(millisec)
    },
    toggleAnimatedLayouts(toggle) {
      if (this.updatingToggle || !this.problemData.id) {
        return
      }
      this.updatingToggle = true
      useJwt.toggleAnimationTemplate(this.problemData.id, {
        animated_layout: toggle,
      }).then(() => {
        this.onFetch()
        this.showSuccess('Template Updated')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.updatingToggle = false
      })
    },
    createAnimatedLayout(payload) {
      if (this.updatingLayout) {
        return
      }
      this.updatingLayout = true
      useJwt.createAnimationTemplate({
        ...payload,
        problem_id: this.problemData.id,
      }).then(() => {
        this.onFetch()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.updatingLayout = false
      })
    },
    updateAnimatedLayout(id, payload) {
      if (this.updatingLayout) {
        return
      }
      this.updatingLayout = true
      useJwt.updateAnimationTemplate(id, {
        ...payload,
        problem_id: this.problemData.id,
      }).then(() => {
        this.onFetch()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.updatingLayout = false
      })
    },
    copyProblem(lessonId) {
      const data = {
        lesson_id: lessonId,
        problem_id: this.problem.id,
      }
      useJwt.copyProblem(data)
        .then(() => {
          this.showSuccess('Problem has been copied successfully.')
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
  },
}
</script>

<style>
.ck-content{
  width: 100%;
}
</style>
