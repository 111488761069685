<template>
  <div>
    <b-col md="12">
      <validation-observer ref="editProblemForm">
        <b-col
          md="12"
          class="px-0"
        >
          <div>
            <b-tabs
              pills
              align="center"
              class="lang-level-tabs"
            >
              <b-tab
                v-for="(level, index) in problem.levels"
                :key="index + level"
                :title="getLangLevelTransText(level)"
                :title-item-class="showLangLevel ? '' : 'd-none'"
                :active="activeTabIndex===index"
                @click="activeTabIndex=index"
              >

                <b-button
                  variant="success"
                  :disabled="isProcessing"
                  @click="showCopyProblemModal = true"
                >
                  {{ $t('labels.copy-problem') }}
                </b-button>
                <b-row class="mt-3">
                  <b-col
                    md="12"
                    lg="4"
                    class="card p-2"
                  >
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.problem-name')"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required|max:256"
                        vid="name"
                      >
                        <b-form-input
                          :id="`name-${index}`"
                          v-model="problem.name"
                          :placeholder="$t('labels.name')"
                          name="name"
                          @keyup.enter="!errors.length ? updateProblem() : null"
                          @blur="!errors.length ? updateProblem() : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Name -->
                    <b-form-group
                      :label="$t('labels.create-problem.tts-support')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="problem.ttsSupport"
                          :options=" [ { text: 'Yes', value: true}, {text: 'No' , value: false} ]"
                          name="tts_support"
                          :state="errors.length > 0 ? false : null"
                          @change="updateProblem()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->
                    <b-form-group
                      :label="$t('labels.create-problem.domain')"
                      label-for="Domain"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Domain"
                        rules="required"
                      >
                        <b-form-select
                          id="domain"
                          v-model="selectedDomain"
                          :options="domains"
                          name="standard"
                          :state="errors.length > 0 ? false : null"
                          @change="fetchStandard"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Domain -->

                    <!-- Standard -->
                    <b-form-group
                      :label="$t('labels.create-problem.standard')"
                      label-for="standard"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="standard"
                        vid="standard"
                      >
                        <b-form-select
                          id="standard"
                          v-model="problem.standard_id"
                          :options="standards"
                          :disabled="selectedDomain ? false:true"
                          name="standard"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Standard -->

                    <!-- Tags -->
                    <b-form-group
                      :label="$t('labels.create-problem.tags')"
                      label-for="tag"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="tag"
                        rules="required"
                      >
                        <v-select
                          v-model="problem.tags"
                          :class="!!errors[0] ? 'v-select-error':''"
                          multiple
                          label="text"
                          :options="tags"
                          placeholder="Please select a Tag"
                          :state="errors.length > 0 ? false : null"
                          @input="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- /Tags -->

                    <!-- Skill Type -->
                    <b-form-group
                      :label="$t('labels.create-problem.skill-type')"
                      label-for="skill-type"
                      rules="required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="skill-type"
                        vid="skill-type"
                      >
                        <b-form-select
                          id="skill-type"
                          v-model="problem.skill_type"
                          :options="skillTypes"
                          name="skill-type"
                          @change="updateProblem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- /Skill Type -->

                    <!-- Theme -->
                    <b-form-group
                      :label="$t('labels.create-problem.theme')"
                      label-for="theme"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="theme"
                        vid="theme"
                      >
                        <b-form-select
                          id="theme"
                          v-model="problem.theme"
                          :options="themes"
                          name="theme"
                          @change="updateTheme"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <theme-preview :theme="problem.theme" />
                    <!-- Theme -->

                    <video-type
                      v-if="videoTimeStamp"
                      :video-time-stamp="videoTimeStamp"
                      @updated="$emit('fetch')"
                    />

                    <!-- Statement & Feedback -->
                    <div>
                      <b-form-group label="Hide Statement">
                        <b-form-checkbox v-model="problem.hideStatement"
                                         switch
                                         @change="updateProblem()"
                        />
                      </b-form-group>
                      <div v-if="!hotspotWrapper">
                        <!-- Statement -->
                        <div v-if="!videoTimeStamp">
                          <label>{{ $t('labels.create-problem.problem-statement') }}</label>
                          <b-tabs
                            pills
                            class="mx-0 px-0 mt-1"
                          >
                            <b-tab
                              title="Image"
                              :active="problem.stType[index] === 'image'"
                              @click="problem.stType[index] = 'image'"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :vid="`statement-image-${level}`"
                              >
                                <image-form
                                  :url="problem.statementImage.url[index]"
                                  @input="handleStatementImage($event, 'statementImage', index)"
                                  @fileTypeInvalid="setError([`statement-image-${level}`], $event)"
                                  @fileSizeInvalid="setError([`statement-image-${level}`], $event)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-tab>
                            <b-tab
                              title="Audio"
                              :active="problem.stType[index] === 'audio'"
                              @click="problem.stType[index] = 'audio'"
                            >
                              <b-form-group
                                :label="$t('labels.create-problem.statement-audio')"
                                label-for="audio"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  :vid="`statement-audio-${level}`"
                                >
                                  <Recorder
                                    :url="problem.statementAudio.url[index]"
                                    @input="setStatementAudio($event, index, 'statementAudio')"
                                    @fileTypeInvalid="setError([`statement-audio-${level}`], $event)"
                                    @fileSizeInvalid="setError([`statement-audio-${level}`], $event)"
                                  />
                                  <input
                                    v-model="problem.statementAudio.url[index]"
                                    hidden
                                  >
                                  <small class="text-danger">{{ errors[0] }}</small><br>
                                </validation-provider>
                              </b-form-group>
                            </b-tab>
                          </b-tabs>
                          <b-form-group
                            :label="$t('labels.create-problem.statement')"
                            label-for="statement"
                          >
                            <div
                              v-if="!problem.statement[index].statementTemplateId"
                            >
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                class="mt-2"
                                block
                                @click="()=>{templateType='statement';fetchTemplate('new')}"
                              >
                                {{ 'Create Template' }}
                              </b-button>
                            </div>
                            <b-button
                              v-else
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              block
                              :disabled="templateBeingFetched === problem.statement[index].statementTemplateId"
                              @click="()=>{templateType='statement';fetchTemplate(problem.statement[index].statementTemplateId)}"
                            >
                              {{ 'Update Template' }}
                            </b-button>
                          </b-form-group>
                        </div>

                        <!-- /Statement -->

                        <!-- Feedback -->
                        <label>{{ $t('labels.create-problem.problem-feedback') }}</label>
                        <b-form-group label="Hide FeedBack">
                          <b-form-checkbox v-model="problem.hideFeedBack"
                                           switch
                                           @change="updateProblem()"
                          />
                        </b-form-group>
                        <b-tabs
                          pills
                          class="mx-0 px-0 mt-1"
                        >
                          <b-tab
                            title="Image"
                            :active="problem.fbType[index] === 'image'"
                            @click="problem.fbType[index] = 'image'"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              :vid="`feedback-image-${level}`"
                            >
                              <image-form
                                :url="problem.feedbackImage.url[index]"
                                @input="handleStatementImage($event, 'feedbackImage', index)"
                                @fileTypeInvalid="setError([`feedback-image-${level}`], $event)"
                                @fileSizeInvalid="setError([`feedback-image-${level}`], $event)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small><br>
                            </validation-provider>
                          </b-tab>
                          <b-tab
                            title="Audio"
                            :active="problem.fbType[index] === 'audio'"
                            @click="problem.fbType[index] = 'audio'"
                          >
                            <b-form-group
                              :label="$t('labels.create-problem.feedback-audio')"
                              label-for="audio"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :vid="`feedback-audio-${level}`"
                              >
                                <Recorder
                                  :url="problem.feedbackAudio.url[index]"
                                  @input="setStatementAudio($event, index, 'feedbackAudio')"
                                  @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                                  @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                                />
                                <input
                                  v-model="problem.feedbackAudio.url[index]"
                                  hidden
                                >
                                <small class="text-danger">{{ errors[0] }}</small><br>
                              </validation-provider>
                            </b-form-group>
                          </b-tab>
                        </b-tabs>
                        <b-form-group
                          :label="$t('labels.create-problem.feedback')"
                          label-for="feedback"
                        >
                          <div v-if="!problem.statement[index].feedbackTemplateId">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              class="mt-2"
                              block
                              @click="()=>{templateType='feedback';fetchTemplate('new')}"
                            >
                              {{ 'Create Template' }}
                            </b-button>
                          </div>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            :disabled="templateBeingFetched === problem.statement[index].feedbackTemplateId"
                            @click="()=>{templateType='feedback';fetchTemplate(problem.statement[index].feedbackTemplateId)}"
                          >
                            {{ 'Update Template' }}
                          </b-button>
                        </b-form-group>
                      <!-- /Feedback -->
                      </div>

                      <div v-if="hotspotWrapper">
                        <b-form-group
                          :label="$t('labels.create-problem.statement')"
                          label-for="statement"
                        >

                          <b-form-input
                            id="statement"
                            v-model="problem.statement[index].statement"
                            placeholder="Statement"
                            @blur="updateProblemStatement(index)"
                            @keyup.enter="updateProblemStatement(index)"
                          />

                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.create-problem.statement-audio')"
                          label-for="audio"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :vid="`statement-audio-${level}`"
                          >
                            <Recorder
                              :url="problem.statementAudio.url[index]"
                              @input="setStatementAudio($event, index, 'statementAudio')"
                              @fileTypeInvalid="setError([`statement-audio-${level}`], $event)"
                              @fileSizeInvalid="setError([`statement-audio-${level}`], $event)"
                            />
                            <input
                              v-model="problem.statementAudio.url[index]"
                              hidden
                            >
                            <small class="text-danger">{{ errors[0] }}</small><br>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.create-problem.feedback')"
                          label-for="feedback"
                        >
                          <b-form-input
                            id="feedback"
                            v-model="problem.statement[index].feedback"
                            placeholder="Feedback"
                            @blur="updateProblemStatement(index)"
                            @keyup.enter="updateProblemStatement(index)"
                          />
                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.create-problem.feedback-audio')"
                          label-for="audio"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :vid="`feedback-audio-${level}`"
                          >
                            <Recorder
                              :url="problem.feedbackAudio.url[index]"
                              @input="setStatementAudio($event, index, 'feedbackAudio')"
                              @fileTypeInvalid="setError([`feedback-audio-${level}`], $event)"
                              @fileSizeInvalid="setError([`feedback-audio-${level}`], $event)"
                            />
                            <input
                              v-model="problem.feedbackAudio.url[index]"
                              hidden
                            >
                            <small class="text-danger">{{ errors[0] }}</small><br>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <!-- <word-net-section
                        :type="'course'"
                        :problem-statement-id="problem.statement[index].id"
                        :main-text="problem.statement[index].statement + ' '+ problem.statement[index].feedback"
                      /> -->
                    </div>
                    <!-- Statement & Problem -->

                  </b-col>
                  <b-col
                    md="12"
                    lg="8"
                    class="pl-md-0 pl-lg-1 pr-0"
                  >
                    <b-card>
                      <!-- CEFR Subject -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-subject')"
                        label-for="cefrSubject"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrSubject"
                          vid="cefrSubject"
                        >
                          <b-form-select
                            id="cefrSubject"
                            v-model="problem.cefr.selectedSubjects[index]"
                            :options="subjectList"
                            name="cefrSubject"
                            @change="fetchCefrDomain(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Subject -->
                      <!-- CEFR Domain -->
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-domain')"
                        label-for="cefrDomain"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrDomain"
                          vid="cefrDomain"
                        >
                          <b-form-select
                            id="cefrDomain"
                            v-model="problem.cefr.selectedDomains[index]"
                            :options="cefrDomainList[index]"
                            :disabled="problem.cefr.selectedSubjects[index] ? false:true"
                            name="cefrDomain"
                            @change="fetchCefrStandard(index, true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- CEFR Domain -->
                      <!-- CEFR Standard -->
                      <b-form-group :label="$t('labels.create-problem.is-case-sensative')">
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            v-model="problem.is_case_sensative"
                            :value="false"
                            class="pt-0 mt-0"
                            @change="updateProblem"
                          >NO
                          </b-form-radio>
                          <b-form-radio
                            v-model="problem.is_case_sensative"
                            :value="true"
                            class="pt-0 mt-0"
                            @change="updateProblem"
                          >Yes
                          </b-form-radio>
                        </div>

                      </b-form-group>
                      <b-form-group
                        :label="$t('labels.create-problem.cefr-standard')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="cefrStandard"
                          vid="cefrStandard"
                        >
                          <b-form-select
                            id="cefrStandard"
                            v-model="problem.cefr.selectedStandards[index]"
                            :options="cefrStandardList[index]"
                            :disabled="problem.cefr.selectedDomains[index] ? false:true"
                            name="cefrStandard"
                            @change="updateCefrStandard(index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Choice Type"
                        label-for="choiceType"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="choiceType"
                          rules="required"
                          vid="choiceType"
                        >
                          <b-form-select
                            id="problemType"
                            v-model="problem.answerChoice"
                            :options="choiceTypes"
                            :state="errors.length > 0 ? false : null"
                            @change="updateProblem()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- problem descriptions -->
                      <b-form-group
                        :label="$t('labels.create-problem.problem-description')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="problemDescription"
                          vid="problemDescription"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.description"
                            :placeholder="$t('labels.create-problem.problem-description')"
                            name="problemDescription"
                            @blur="updateProblem"
                            @keyup.enter="updateProblem"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <div v-if="problem.questions.extraFile[index][0]"
                           class="border border-info p-2"
                      >
                        <image-form
                          :caption="problem.questions.extraFile[index][0].extra_caption"
                          :show-caption="true"
                          :url="problem.questions.extraFile[index][0].extra_image"
                          @input="handleExtraFileImage($event, problem.questions.extraFile[index][0], index, 0)"
                          @fileTypeInvalid="setError([`question-image-${level}`], $event)"
                          @fileSizeInvalid="setError([`question-image-${level}`], $event)"
                        />
                        <input
                          v-model="problem.questions.extraFile[index][0].extra_image"
                          hidden
                        >
                      </div>
                      <!-- Page Link -->
                      <b-form-group
                        :label="$t('labels.create-problem.page-link')"
                        label-for="cefrStandard"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="pageLink"
                          vid="pageLink"
                        >
                          <b-form-input
                            id="pageLink"
                            v-model="problem.pageLink[index]"
                            :placeholder="$t('labels.create-problem.placeholder.page-link')"
                            name="pageLink"
                            @blur="updatePageLink(index, 0)"
                            @keyup.enter="updatePageLink(index, 0)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Question -->
                      <b-form-group
                        v-if="problem.questionType === 'text' && problemData.hotspot_point?.hotspot?.type !== 'hotspot-drag'"
                        :label="$t('labels.create-problem.question')"
                        label-for="question"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="question"
                          rules="required"
                          vid="question"
                        >
                          <b-form-input
                            id="question"
                            v-model="problem.questions.text[index][0].text"
                            :placeholder="$t('labels.create-problem.placeholder.question')"
                            name="question"
                            @blur="updateQuestion(index, 0)"
                            @keyup.enter="updateQuestion(index, 0)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- Image -->
                      <b-form-group
                        v-else-if="problem.questionType === 'image'"
                        :label="$t('labels.create-problem.question')"
                        :label-for="`question-${level}`"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="question"
                          :vid="`question-${level}`"
                        >
                          <image-form
                            :url="problem.questions.imageUrl[index][0].text"
                            :caption="problem.questions.caption[index][0].text"
                            :show-caption="true"
                            @input="handleImage($event, 'questions', index, 0)"
                            @fileTypeInvalid="setError([`question-${level}`], $event)"
                            @fileSizeInvalid="setError([`question-${level}`], $event)"
                          />
                          <input
                            v-model="problem.questions.imageUrl[index][0].text"
                            hidden
                          >
                          <small class="text-danger">{{ errors[0] }}</small><br>
                        </validation-provider>
                      </b-form-group>
                      <!-- Image -->
                      <!-- Audio -->
                      <b-form-group
                        v-else-if="problem.questionType === 'audio'"
                        :label="$t('labels.create-problem.question')"
                        label-for="audio"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="question"
                          :vid="`question-audio-${level}`"
                        >
                          <Recorder
                            :url="problem.questions.audioUrl[index][0].text"
                            @input="setAudio(index, 0, 'questions', $event)"
                            @fileTypeInvalid="setError([`question-audio-${level}`], $event)"
                            @fileSizeInvalid="setError([`question-audio-${level}`], $event)"
                          />
                          <input
                            v-model="problem.questions.audioUrl[index][0].text"
                            hidden
                          >
                          <small class="text-danger">{{ errors[0] }}</small><br>
                        </validation-provider>
                      </b-form-group>
                      <!-- Question -->

                      <!-- Answers -->
                      <b-row
                        v-for="i in answerLength"
                        :key="i"
                      >
                        <b-col md="8">
                          <b-form-group
                            v-if="problem.answerType === 'text'"
                            :label="$t('labels.create-problem.answer')"
                            label-for="answer"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="answer"
                              rules="required"
                              vid="answer"
                            >
                              <b-form-input
                                :id="`answer-${index}-${i}-matching`"
                                v-model="problem.answers[problem.answerType][index][i-1].text"
                                :placeholder="$t('labels.create-problem.placeholder.answer')"
                                name="answer"
                                @blur="updateAnswer(index, i - 1)"
                                @keyup.enter="updateAnswer(index, i - 1)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <!-- Image -->
                          <b-form-group
                            v-else-if="problem.answerType === 'image'"
                            :label="$t('labels.create-problem.answer')"
                            label-for="article-image"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="answer"
                              :vid="`answer-multiple-${level + i}`"
                            >
                              <image-form
                                :url="problem.answers.imageUrl[index][i-1].text"
                                :caption="problem.answers.caption[index][i-1].text"
                                :show-caption="true"
                                @input="handleImage($event, 'answers', index, i-1)"
                                @fileTypeInvalid="setError([`answer-multiple-${level + i}`], $event)"
                                @fileSizeInvalid="setError([`answer-multiple-${level + i}`], $event)"
                              />
                              <input
                                v-model="problem.answers.imageUrl[index][i -1].text"
                                hidden
                              >
                              <small class="text-danger">{{ errors[0] }}</small><br>
                            </validation-provider>
                          </b-form-group>
                          <!-- Image -->
                          <!-- Audio -->
                          <b-form-group
                            v-else-if="problem.answerType === 'audio'"
                            :label="$t('labels.create-problem.answer')"
                            label-for="article-image"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="answer"
                              :vid="`answer-audio-${level + i}`"
                            >
                              <Recorder
                                :url="problem.answers.audioUrl[index][i -1].text"
                                @input="setAudio(index, i-1, 'answers', $event)"
                                @fileTypeInvalid="setError([`answer-audio-${level + i}`], $event)"
                                @fileSizeInvalid="setError([`answer-audio-${level + i}`], $event)"
                              />
                              <input
                                v-model="problem.answers.audioUrl[index][i -1].text"
                                hidden
                              >
                              <small class="text-danger">{{ errors[0] }}</small><br>
                            </validation-provider>
                          </b-form-group>
                          <!-- Audio -->
                        </b-col>

                        <b-col
                          class="my-auto"
                          md="2"
                        >
                          <BFormCheckbox
                            v-model="problem.answers[problem.answerType][index][i-1].is_correct"
                            name="checkbox-1"
                            :value="true"
                            @change="updateIsCorrect(index, i-1)"
                          >{{ $t('labels.create-problem.is-correct') }}</BFormCheckbox>
                        </b-col>

                        <b-col
                          class="my-2"
                          md="2"
                        >
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon mr-1"
                            @click="toggleDeleteModal(i-1)"
                          >
                            <feather-icon icon="TrashIcon" />
                            {{ $t('Delete') }}
                          </b-button>
                        </b-col>
                      </b-row>
                      <!-- Answers -->
                      <hr>

                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="btn-icon"
                        @click="modal.show = true"
                      >
                        <feather-icon icon="PlusIcon" />
                        {{ $t('Add-New') }}
                      </b-button>
                    </b-card>
                    <b-card>
                      <b-col
                        class="d-flex justify-content-between"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-success"
                          class="mr-2"
                          @click="$emit('showQuestionPreview',getDataForQuestionPreview())"
                        >
                          {{ $t("actions.preview") }}
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="$router.push({ path: `/${AUTH_USER().usertype}/problem`, query: { lesson_id: $route.query.lesson_id } })"
                        >
                          {{ $t("actions.back") }}
                        </b-button>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>

              </b-tab>
              <b-col
                md="12"
                lg="4"
                class="p-0 m-0"
              >
                <hot-spot
                  v-if="hotspotWrapper"
                  :hotspot-point="problemData.hotspot_point"
                  @pointUpdated="$emit('fetch')"
                />
              </b-col>
            </b-tabs>
          </div>
        </b-col>
      </validation-observer>
    </b-col>

    <!-- add item -->
    <add-modal
      :toggle="modal.show"
      :answer-type="problem.answerType"
      :question-ids="problem.questions[problem.questionType].map(q => q[0].id)"
      @close="modal.show = false"
      @fetch="$emit('fetch')"
    />
    <!-- add item -->

    <!-- delete item -->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-answer')"
      @ok="deleteAnswer()"
    >
      <b-card-text>
        {{ $t("questions.delete-answer") }}
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete item -->

    <!-- create edit template -->
    <create-template
      :show="visibleTemplate!=null"
      :template="problemTemplates[visibleTemplate]"
      :title="`Update ${templateType} Template`"
      :extra-template-para="extraTemplatePara"
      @removeExtraParams="extraTemplatePara = null"
      @templateStored="templateStored"
      @close="visibleTemplate= null"
    />
    <!-- create edit template -->

    <copy-to-lesson-modal
      :problem-id="problemData.id"
      :show="showCopyProblemModal"
      @close="showCopyProblemModal=false"
    />
  </div>

</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BSpinner,
  BTab,
  BTabs,
  BFormCheckbox,
  BModal,
  BCardText,
  BCard,
  BFormRadio,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ThemePreview from '@views/common/components/ThemePreview.vue'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
// import WordNetSection from '@views/common/lessons/components/WordNetSection.vue'
import vSelect from 'vue-select'
import SkillTypes from '@/const/skillTypes'
import VideoType from '../../components/edit/VideoType.vue'
import HotSpot from '../../components/edit/HotSpot.vue'
import Recorder from '../../components/recorder.vue'
import AddModal from './AddModal.vue'
import ImageForm from '../../components/ImageForm.vue'
import CopyToLessonModal from '../components/CopyToLessonModal.vue'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BTab,
    BTabs,
    BFormCheckbox,
    BModal,
    BCardText,
    Recorder,
    AddModal,
    BCard,
    VideoType,
    HotSpot,
    ThemePreview,
    CreateTemplate,
    ImageForm,
    // WordNetSection,
    CopyToLessonModal,
    vSelect,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  props: {
    problemData: {
      type: Object,
      default: () => {},
    },
    domains: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a domain' }],
    },
    subjectList: {
      type: Array,
      default: () => [{ value: null, text: 'Please select a subject' }],
    },
    tags: {
      type: Array,
      default: () => {},
    },
  },
  data: () => ({
    isProcessing: false,
    showCopyProblemModal: false,
    modal: {
      show: false,
    },
    activeTabIndex: 3,
    problem: {
      allLevels: ['A1', 'A2', 'B1', 'B2'],
      levels: [],
      activeTabIndex: 3,
      questionType: 'text',
      answerType: 'text',
      questionOptions: [
        { value: 'text', text: 'Text', disabled: false },
        { value: 'image', text: 'Image', disabled: false },
        { value: 'audio', text: 'Audio', disabled: false },
      ],
      answerOptions: [
        { value: 'text', text: 'Text', disabled: false },
        { value: 'image', text: 'Image', disabled: false },
        { value: 'audio', text: 'Audio', disabled: false },
      ],
      questions: {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        audio: [[], [], [], []],
        caption: [[], [], [], []],
        audioUrl: [[], [], [], []],
      },
      answers: {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        caption: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
      },
      standard_id: null,
      name: '',
      statement: [],
      statementImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackImage: { url: ['', '', '', ''], file: ['', '', '', ''] },
      stType: [],
      fbType: [],
      statementAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      feedbackAudio: { url: ['', '', '', ''], file: ['', '', '', ''] },
      theme: null,
      cefr: {
        selectedSubjects: [null, null, null, null],
        selectedDomains: [null, null, null, null],
        selectedStandards: [null, null, null, null],
      },
      pageLink: [],
      is_case_sensative: false,
      skill_type: '',
    },
    answerLength: 1,
    isValid: false,
    changed: {
      name: false,
      questions: false,
      answer: true,
      statement: false,
      feedback: false,
      standard_id: false,
    },
    deleteIndex: null,
    selectedDomain: null,
    standards: [{ value: null, text: 'Please select a standard' }],
    themes: [
      { value: 0, text: 'Default' },
      { value: 1, text: 'Theme-1' },
      { value: 2, text: 'Theme-2' },
      { value: 3, text: 'Theme-3' },
      { value: 4, text: 'Theme-4' },
      { value: 5, text: 'Theme-5' },
      { value: 6, text: 'Theme-6' },
      { value: 7, text: 'Theme-7' },
      { value: 8, text: 'Theme-8' },
      { value: 9, text: 'Theme-9' },
    ],
    cefrDomainList: [
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
      [{ value: null, text: 'Please select a domain' }],
    ],
    cefrStandardList: [
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
      [{ value: null, text: 'Please select a standard' }],
    ],

    // extra data
    visibleTemplate: null,
    templateBeingFetched: null,
    problemTemplates: {},
    templateType: '',
    videoTimeStamp: null,
    hotspotWrapper: false,
    lessons: null,
    extraTemplatePara: null,
    ttsSupport: false,
    skillTypes: SkillTypes,
    choiceTypes: ['single', 'multiple'],
  }),
  computed: {
    showLangLevel() {
      let status = false
      if (this.problemData.questions.length > 1) {
        status = true
      }
      return status
    },
  },
  watch: {
    problemData() {
      this.setPage()
    },
    activeTabIndex() {
      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)
    },
  },
  created() {
    if (this.$route.query.lang_level) {
      this.activeTabIndex = Number(this.$route.query.lang_level) - 1
    }
    this.setPage()
    this.fetchLessons()
    // if (this.problemData?.questions && this.problemData?.questions.length === 1) this.activeTabIndex = 0
  },
  methods: {
    templateStored(data) {
      if (Number.isInteger(this.visibleTemplate)) {
        this.problemTemplates[this.visibleTemplate] = data
        this.visibleTemplate = null
        return
      }
      this.problemTemplates[this.activeTabIndex + this.templateType] = data
      const newStatment = data.problemstatment
      const oldStatment = this.problem.statement
      oldStatment[this.activeTabIndex] = {
        ...oldStatment[this.activeTabIndex],
        statementTemplateId: newStatment.st_template_id,
        feedbackTemplateId: newStatment.fb_template_id,
      }
      this.visibleTemplate = null
      this.$set(this.problem, 'statement', oldStatment)
    },
    fetchTemplate(id, viewTemplate = true) {
      if (id === 'new') {
        this.extraTemplatePara = {
          problem_id: this.problemData.id,
          lang_level: this.activeTabIndex + 1,
          st_type: this.templateType,
          standard_id: this.problem.standard_id,
        }
        this.visibleTemplate = this.activeTabIndex + this.templateType
        return null
      }
      return new Promise(resolve => {
        if (this.problemTemplates[id]) {
          if (viewTemplate) this.visibleTemplate = id
          return
        }

        this.templateBeingFetched = id
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          if (viewTemplate) this.visibleTemplate = id
          resolve('')
        }).finally(() => {
          this.templateBeingFetched = null
        })
      })
    },
    fetchLessons() {
      useJwt.getAllLessons().then(res => {
        this.lessons = res.data.data
      })
    },
    setPage() {
      // set standard and problem name
      this.selectedDomain = this.problemData.problem_set.standard.domain.id
      this.problem.standard_id = this.problemData.problem_set.standard_id
      this.problem.tags = this.problemData.tags
      this.problem.ttsSupport = !!this.problemData.tts_support
      this.problem.name = this.problemData.name
      if (this.problemData.rules) {
        this.problem.hideStatement = this.JSON_PARSE(this.problemData.rules)?.hide_statement
        this.problem.hideFeedBack = this.JSON_PARSE(this.problemData.rules)?.hide_feedback
      }

      // update rules
      if (this.problemData.rules) {
        this.problem.answerChoice = this.JSON_PARSE(this.problemData.rules)?.choice_type
      }
      this.standards = this.problemData.problem_set.standard.domain.standards.map(s => ({ value: s.id, text: s.name }))
      this.problem.theme = this.problemData.theme
      this.videoTimeStamp = this.problemData.video_time_stamp
      this.hotspotWrapper = !!this.problemData.hotspot_point
      this.problem.skill_type = this.problemData.skill_type
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      // set statement and feedback
      let i = 0
      while (i < 4) {
        this.problem.statement.push({
          statement: '', id: null, feedback: '',

        })
        i += 1
      }
      this.problemData.problem_statements.forEach(data => {
        this.problem.statement[data.lang_level - 1] = {
          statementTemplateId: data.st_template_id,
          feedbackTemplateId: data.fb_template_id,
          initialStatement: data.statement,
          initialFeedback: data.feedback,
          statement: data.statement,
          id: data.id,
          feedback: data.feedback,
          fb_type: data.fb_type,
          st_type: data.st_type,
        }
        // page links
        this.problem.pageLink[data.lang_level - 1] = data.page_link

        if (data.standard) {
          this.problem.cefr.selectedSubjects[data.lang_level - 1] = data.standard.domain.subject_id
          this.fetchCefrDomain(data.lang_level - 1, false)
          this.problem.cefr.selectedDomains[data.lang_level - 1] = data.standard.domain.id
          this.fetchCefrStandard(data.lang_level - 1, false)
          this.problem.cefr.selectedStandards[data.lang_level - 1] = data.standard_id
        }
        this.problem.stType.push(data.st_type)
        this.problem.fbType.push(data.fb_type)
        if (data.st_type === 'image') this.problem.statementImage.url[data.lang_level - 1] = data.st_image
        if (data.st_type === 'audio') this.problem.statementAudio.url[data.lang_level - 1] = data.st_image
        if (data.fb_type === 'image') this.problem.feedbackImage.url[data.lang_level - 1] = data.fb_image
        if (data.fb_type === 'audio') this.problem.feedbackAudio.url[data.lang_level - 1] = data.fb_image
      })

      // set question and answer
      this.problem.questions = {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        caption: [[], [], [], []],
        audio: [[], [], [], []],
        extraFile: [[], [], [], []],
        audioUrl: [[], [], [], []],
      }
      this.problem.description = this.problemData.description
      this.problem.answers = {
        text: [[], [], [], []],
        image: [[], [], [], []],
        imageUrl: [[], [], [], []],
        caption: [[], [], [], []],
        audio: [[], [], [], []],
        audioUrl: [[], [], [], []],
      }

      this.problem.levels = []

      // if linear problem type, set only one language level
      if (this.problemData?.questions?.find(q => q.lang_level !== '1') && !this.problemData?.isLinearLesson) {
        this.problem.levels = [...this.problem.allLevels]
      } else {
        const tabIndex = this.problemData.questions ? this.problemData.questions[0]?.lang_level - 1 : 0
        const newLevelArray = new Array(4).fill('')
        newLevelArray[tabIndex] = this.problem.allLevels[tabIndex]
        this.problem.levels = newLevelArray
        this.activeTabIndex = tabIndex
      }

      this.problemData.questions.forEach(data => {
        this.problem.questionType = data.data_type
        const extraFile = data.extra_file ? this.JSON_PARSE(data.extra_file) : null
        this.problem.questions.extraFile[data.lang_level - 1].push({ extra_caption: extraFile?.extra_caption, extra_image: extraFile?.extra_image })
        if (data.data_type === 'text') this.problem.questions[data.data_type][data.lang_level - 1].push({ id: data.id, text: data.text })
        else if (data.data_type === 'image') {
          this.problem.questions.imageUrl[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.image[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.caption[data.lang_level - 1].push({ text: this.JSON_PARSE(data.extra_file)?.caption })
        } else if (data.data_type === 'audio') {
          this.problem.questions.audioUrl[data.lang_level - 1].push({ id: data.id, text: data.file })
          this.problem.questions.audio[data.lang_level - 1].push({ id: data.id, text: data.file })
        }

        if (data.answers[0]) this.problem.answerType = data.answers[0].data_type
        data.answers.forEach(a => {
          const type = data.answers[0].data_type
          if (type === 'text') {
            this.problem.answers.text[data.lang_level - 1].push({
              id: a.id, text: a.text, is_correct: !!a.is_correct, question_id: data.id,
            })
          } else if (type === 'image') {
            this.problem.answers.image[data.lang_level - 1].push({
              id: a.id, text: a.file || a.text, is_correct: !!a.is_correct, question_id: data.id,
            })
            this.problem.answers.imageUrl[data.lang_level - 1].push({
              id: a.id, text: a.file || a.text, is_correct: !!a.is_correct, question_id: data.id,
            })
            this.problem.answers.caption[data.lang_level - 1].push({ id: a.id, text: this.JSON_PARSE(data.answers[0]?.extra_file)?.caption })
          } else if (type === 'audio') {
            this.problem.answers.audioUrl[data.lang_level - 1].push({
              id: a.id, text: a.file || a.text, is_correct: !!a.is_correct, question_id: data.id,
            })
            this.problem.answers.audio[data.lang_level - 1].push({
              id: a.id, text: a.file || a.text, is_correct: !!a.is_correct, question_id: data.id,
            })
          }
        })
      })
      this.answerLength = this.problem.answers[this.problem.answerType][this.activeTabIndex].length
      this.problem.answers[this.problem.answerType].forEach(answer => {
        let len = answer.length
        while (len < this.answerLength) {
          answer.push({
            id: null, text: '', is_correct: false, question_id: null,
          })
          len += 1
        }
      })
      this.problem.questions[this.problem.questionType].forEach(question => {
        if (!question.length) {
          question.push({
            id: null, text: '',
          })
        }
      })
      if (this.problem.statement[this.activeTabIndex].statementTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].statementTemplateId, false)
      if (this.problem.statement[this.activeTabIndex].feedbackTemplateId) this.fetchTemplate(this.problem.statement[this.activeTabIndex].feedbackTemplateId, false)
      this.$emit('ready')
    },
    updateTheme() {
      const config = {
        theme: `${this.problem.theme}`,
      }
      useJwt.updateTheme(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblem() {
      const config = {
        lesson_id: this.$route.query.lesson_id,
        name: this.problem.name,
        standard_id: this.problem.standard_id,
        tags: this.problem.tags,
        is_case_sensative: this.problem.is_case_sensative,
        tts_support: this.problem.ttsSupport,
        skill_type: this.problem.skill_type,
        description: this.problem.description,
        rules: {
          choice_type: this.problem.answerChoice,
          hide_statement: this.problem.hideStatement,
          hide_feedback: this.problem.hideFeedBack,
        },
      }
      this.$store.commit('problem/SET_TTS_SUPPORT', !!this.problem.ttsSupport)
      useJwt.updateProblem(this.$route.query.problem_id, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    handleExtraFileImage({ url, caption }, object, i, j) {
      // eslint-disable-next-line no-param-reassign
      object.extra_image = url
      // eslint-disable-next-line no-param-reassign
      object.extra_caption = caption
      console.log(i, j)
      this.updateQuestion(i, j)
    },
    handleImage({ image, url, caption }, type, i, j) {
      this.problem[type].image[i][j].text = image
      const imgList = this.problem[type].imageUrl
      imgList[i][j].text = url
      this.problem[type].imageUrl = [...imgList]
      const imgCaption = this.problem[type].caption
      imgCaption[i][j] = caption
      this.problem[type].caption = [...imgCaption]
      if (type === 'questions') this.updateQuestion(i, j)
      else if (type === 'answers') this.updateAnswer(i, j)
    },
    handleStatementImage({ url, image }, type, index) {
      this.problem[type].url[index] = url
      if (type === 'statementImage') this.updateProblemStatementImage(image, index, 'st_image')
      else if (type === 'feedbackImage') this.updateProblemStatementImage(image, index, 'fb_image')
    },
    updateCefrStandard(index) {
      useJwt.updateCefrStandard(this.problem.statement[index].id, this.problem.cefr.selectedStandards[index])
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatement(level) {
      const statement = this.problem.statement[level]
      if (statement.initialStatement !== statement.statement || statement.initialFeedback !== statement.feedback) {
        const config = {
          lang_level: level + 1,
          statement: statement.statement,
          feedback: statement.feedback,
          _method: 'put',
        }
        useJwt.updateProblemStatement(this.problem.statement[level].id, config)
          .then(response => {
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.$emit('fetch')
          })
      }
    },
    updateProblemStatementImage(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'image')
      else if (type === 'fb_image') data.append('fb_type', 'image')
      data.append('_method', 'put')

      useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    updateProblemStatementAudio(file, level, type) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('lang_level', level + 1)
      data.append('statement', this.problem.statement[level].statement)
      data.append('feedback', this.problem.statement[level].feedback)
      data.append([type], file)
      if (type === 'st_image') data.append('st_type', 'audio')
      else if (type === 'fb_image') data.append('fb_type', 'audio')
      data.append('_method', 'put')
      if (!this.problem.statement[level]) {
        console.error('no problem stament found')
      } else {
        useJwt.updateProblemStatement(this.problem.statement[level].id, data, config)
          .then(response => {
            this.showSuccessMessage(response)
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
          .finally(() => {
            this.$emit('fetch')
          })
      }
    },
    setAudio(i, j, type, { audio, url }) {
      this.problem[type].audio[i][j].text = audio
      this.problem[type].audioUrl[i][j].text = url
      if (audio) {
        if (type === 'questions') this.updateQuestion(i, j)
        else if (type === 'answers') this.updateAnswer(i, j)
      }
    },
    setStatementAudio({ audio, url }, i, type) {
      this.problem[type].url[i] = url
      this.problem[type].file[i] = audio
      if (audio) {
        if (type === 'statementAudio') this.updateProblemStatementAudio(audio, i, 'st_image')
        else if (type === 'feedbackAudio') this.updateProblemStatementAudio(audio, i, 'fb_image')
      }
    },
    updateQuestion(i, j) {
      const question = this.problem.questions[this.problem.questionType][i][j]
      const caption = this.problem.questions.caption?.[i][j]
      const extraFile = this.problem.questions.extraFile?.[i][j]

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', `${i + 1}`)
      data.append('problem_id', this.$route.query.problem_id)
      data.append('text', question.text)
      if (caption && !question.text && this.problem.questionType === 'image') {
        data.append('caption', caption)
        data.append('text', 'IMAGE_CAPTION')
      } else {
        data.append('text', question.text)
      }

      data.append('ques_type', this.problem.questionType)

      data.append('extra_image', extraFile.extra_image)
      data.append('extra_caption', extraFile.extra_caption)
      data.append('_method', 'put')
      console.log(extraFile)
      // data.append('extra_file_caption', )
      useJwt.updateQuestion(question.id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)\
          this.showError(error.response.data.errors.text[0])
        })
    },
    updateAnswer(i, j) {
      const answer = this.problem.answers[this.problem.answerType][i][j]
      const caption = this.problem.answers.caption?.[i][j]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }

      const data = new FormData()
      data.append('lang_level', `${i + 1}`)
      data.append('problem_id', this.$route.query.problem_id)
      data.append('text', answer.text)
      data.append('is_correct', answer.is_correct ? '1' : '0')
      if (caption && !answer.text && this.problem.answerType === 'image') {
        data.append('caption', caption)
        data.append('text', 'IMAGE_CAPTION')
      } else {
        data.append('text', answer.text)
      }

      data.append('ans_type', this.problem.answerType)
      data.append('_method', 'put')

      useJwt.updateAnswer(answer.id, data, config)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('fetch')
        })
        .catch(error => {
          // this.showErrorMessage(error)\
          this.showError(error.response.data.errors.text[0])
        })
    },
    updateIsCorrect(i, j) {
      const answer = this.problem.answers[this.problem.answerType][i][j]
      const data = {
        is_correct: answer.is_correct ? 1 : 0,
        _method: 'put',
        id: answer.id,
      }
      useJwt.updateAnswerCorrection(answer.id, data)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.$emit('fetch')
        })
    },
    toggleDeleteModal(index) {
      this.$refs['modal-delete'].toggle()
      this.deleteIndex = index
    },
    deleteAnswer() {
      const requests = []
      this.problem.answers[this.problem.answerType].forEach(a => {
        if (a[this.deleteIndex].id) requests.push(useJwt.deleteAnswer(a[this.deleteIndex].id))
      })
      Promise.all(requests).then(response => {
        this.showSuccessMessage(response[0])
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.problem.answers[this.problem.answerType].forEach(answer => {
          answer.splice(this.deleteIndex, 1)
        })
        this.$emit('fetch')
      })
    },
    addNewField() {
      this.answerLength += 1
      this.problem.answers[this.problem.answerType].forEach((answer, i) => {
        answer.push({
          id: null, text: '', is_correct: false, question_id: this.problem.questions[i][0].id,
        })
      })
    },
    fetchStandard() {
      if (this.selectedDomain) {
        useJwt.getStandardsByDomain(this.selectedDomain).then(response => {
          this.standards = [{ value: null, text: 'Please select a standard' }, ...response.data.data.map(s => ({ value: s.id, text: s.name }))]
        })
      }
      this.problem.standard_id = null
    },
    fetchCefrDomain(index, setNull) {
      const setDomain = domains => {
        this.cefrDomainList[index] = [{ value: null, text: 'Please select a domain' }, ...domains.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cefr.selectedDomains[index] = null
        if (setNull) this.problem.cefr.selectedStandards[index] = null
        this.cefrDomainList.push('')
        this.cefrDomainList.pop()
      }
      const id = Number(this.problem.cefr.selectedSubjects[index])
      const cachedDomain = this.subjectList?.find(subject => Number(subject.id) === id)
      if (cachedDomain) {
        setDomain(cachedDomain.domains)
        return
      }
      useJwt.getCefrDomain(id).then(response => {
        setDomain(response.data.data)
      })
    },
    fetchCefrStandard(index, setNull) {
      const setStandard = standard => {
        this.cefrStandardList[index] = [{ value: null, text: 'Please select a standard' }, ...standard.map(s => ({ value: s.id, text: s.name }))]
        if (setNull) this.problem.cefr.selectedStandards[index] = null
        this.cefrStandardList.push('')
        this.cefrStandardList.pop()
      }
      const id = Number(this.problem.cefr.selectedDomains[index])
      let cachedStandard = this.subjectList?.find(subject => subject.domains?.find(domain => Number(domain.id) === Number(id)))
      if (cachedStandard) {
        cachedStandard = cachedStandard.domains?.find(domain => Number(domain.id) === Number(id))
        setStandard(cachedStandard.standards)
      } else {
        useJwt.getCefrStandard(id).then(response => {
          setStandard(response.data.data)
        })
      }
    },
    getDataForQuestionPreview() {
      const statementAndFeedback = this.problem.statement[this.activeTabIndex]
      const index = this.activeTabIndex
      return {
        theme: this.problem.theme,
        hasAnimatedLayout: this.problemData.animated_layout,
        animatedTemplateId: this.problemData.animated_template_id,
        problemType: 'multiple',
        hideStatement: !!this.problem.hideStatement,
        hideFeedBack: !!this.problem.hideFeedBack,
        rules: {
          choice_type: this.problem.answerChoice,
        },
        feedback: {
          type: statementAndFeedback.fb_type,
          text: statementAndFeedback.feedback,
          image: statementAndFeedback.fb_type === 'image' ? this.problem.feedbackImage?.url[index] : null,
          audio: statementAndFeedback.fb_type === 'audio' ? this.problem.feedbackAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.feedbackTemplateId || (`${this.activeTabIndex}feedback`)],
        },
        statement: {
          type: statementAndFeedback.st_type,
          text: statementAndFeedback.statement,
          image: statementAndFeedback.st_type === 'image' ? this.problem.statementImage?.url[index] : null,
          audio: statementAndFeedback.st_type === 'audio' ? this.problem.statementAudio?.url[index] : null,
          template: this.problemTemplates[statementAndFeedback.statementTemplateId || (`${this.activeTabIndex}statement`)],
          video: this.videoTimeStamp,
        },
        // eslint-disable-next-line eqeqeq
        questions: this.problemData.questions.filter(e => e.lang_level == (this.activeTabIndex + 1).toString()),
        level: this.activeTabIndex + 1,
      }
    },
    setError(vid, msg) {
      this.$refs.editProblemForm.setErrors({ [vid]: [msg] })
    },
    copyLesson(lessonId) {
      this.$emit('copyProblem', lessonId)
    },
    updatePageLink(i) {
      const updatePrams = {
        lang_level: i + 1,
        problem_id: this.$route.query.problem_id,
        page_link: this.problem.pageLink[i],
      }
      useJwt.updatePageLink(updatePrams).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('fetch')
      })
      // useJwt.updatePageLink()
    },
  },
}
</script>
